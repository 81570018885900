<script>
	export let author;
	export let title;
	export let interactive;
	export let full_image;
</script>

<main>
    <img src={full_image} alt='Screenshot of a dashboard from the IronViz submissions'>
    <a href={interactive} class='card_overlay' target='blank'> 
        <h3 class='author'>{author}</h3>
        <h3 class='title'>{title}</h3>
    </a>
</main>

<style>
    main {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 4px 4px 4px #bfbfbf;
        margin: 20px 0;
    }

    .card_overlay {
        position: absolute;
        top:0;
        left:0;
        opacity:0;
        height: 100%;
        width: 100%;
        transition: all .5s ease-in-out;
        padding-top: 12px;
    }

    h3 {
        font-family: 'Open Sans';
        margin-left: 12px;
        color: #333;
    }

    h3.author {
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    main:hover .card_overlay {
        transition: all .5s ease-in-out;
        opacity:1;
        /* background-color: rgba(120, 120, 120, 0.3); */
    }

    img {
        width: 100% !important;
        height: auto !important;
	}

    main:hover img {
        filter: blur(8px)
    }
</style>