export const submissions = [
  {
    interactive:
      "https://public.tableau.com/app/profile/ameed.ahmed/viz/TableauLOTR-AmeedAhmed/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ta/TableauLOTR-AmeedAhmed/Dashboard1/1.png",
    title: "Lord of The Rings Visualization",
    author: "Ameed Ahmed",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jordan3657/viz/TennisWarriors-IronViz2021/WomensTennisWinnerDashboard",
    full_image:
      "https://public.tableau.com/static/images/Te/TennisWarriors-IronViz2021/WomensTennisWinnerDashboard/1.png",
    title: "Tennis Warriors - Iron Viz 2021",
    author: "Jordan Strickland",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jeremy.johnson/viz/IronViz2021-MagnificentThermalPoolsofYellowstone/Dashboard3",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021-MagnificentThermalPoolsofYellowstone/Dashboard3/1.png",
    title: "Magnificent Thermal Pools of Yellowstone",
    author: "Jeremy Johnson",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/huey.wen.perng/viz/Malaysiaforestedlandusedtrendfrom2010-17/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ma/Malaysiaforestedlandusedtrendfrom2010-17/Dashboard1/1.png",
    title: "Malaysia forested trend and land used from 2001-2017",
    author: "Sam Perng",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/nicholas.pillsbury/viz/TTRPGJoyofSkills-Ironviz2021/DiversityofSkills",
    full_image:
      "https://public.tableau.com/static/images/TT/TTRPGJoyofSkills-Ironviz2021/DiversityofSkills/1.png",
    title: "Diversity of Skills in Table Top Role-Playing Games",
    author: "Nicholas Pillsbury",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/snigdha.alathur/viz/SpotifyMusiViz/SpotifyMusi-Viz",
    full_image:
      "https://public.tableau.com/static/images/Sp/SpotifyMusiViz/SpotifyMusi-Viz/1.png",
    title: "Spotify MusiViz",
    author: "Snigdha Alathur",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/matt.eads/viz/Ironviz2021-Golfer/Ironviz2021-Golfer",
    full_image:
      "https://public.tableau.com/static/images/Ir/Ironviz2021-Golfer/Ironviz2021-Golfer/1.png",
    title: "A Golfer's Journey",
    author: "Matthew Eads",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/fredfery/viz/PinkFloydSales1967-2014/PinkFloydSales1967-20142",
    full_image:
      "https://public.tableau.com/static/images/Pi/PinkFloydSales1967-2014/PinkFloydSales1967-20142/1.png",
    title: "Pink Floyd Sales 1967 - 2014",
    author: "Frederic Fery",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/brian.moore7221/viz/DiversityintheMarvelCinematicUniverse/DiversityintheMCU",
    full_image:
      "https://public.tableau.com/static/images/Di/DiversityintheMarvelCinematicUniverse/DiversityintheMCU/1.png",
    title: "Diversity in the Marvel Cinematic Universe",
    author: "Brian Moore",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/maxwell.titsworth/viz/OurGlobalMusic-ANetworkAnalysis/Introduction",
    full_image:
      "https://public.tableau.com/static/images/Ou/OurGlobalMusic-ANetworkAnalysis/Introduction/1.png",
    title: "Our Global Music - How pop music is shared across the world. ",
    author: "Maxwell Titsworth",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/anna.kourouniotis/viz/IronViz2021EqualityinFemaleFootball/Equality",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021EqualityinFemaleFootball/Equality/1.png",
    title: "#IronViz2021 Equality in Female Football",
    author: "Anna Kourouniotis",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sparsonsdataviz/viz/RiversofTimeMarvelStudiosMCUIronviz2021/RiversofTime",
    full_image:
      "https://public.tableau.com/static/images/Ri/RiversofTimeMarvelStudiosMCUIronviz2021/RiversofTime/1.png",
    title: "Rivers of Time",
    author: "Samuel Parsons",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/pradeepkumar.g/viz/TheShowThatBringsMeJoy/IronViz_2021",
    full_image:
      "https://public.tableau.com/static/images/Th/TheShowThatBringsMeJoy/IronViz_2021/1.png",
    title: "The Show That Brings Me Joy | IronViz 2021",
    author: "Pradeep Kumar G",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ravi.kumar.n/viz/IronViz2021Biodiversityatrisk/IronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021Biodiversityatrisk/IronViz2021/1.png",
    title: "Biodiversity at Risk",
    author: "Ravi Kumar N",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jessica.moon/viz/IronViz2021OdetoNat/OdetoNat",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021OdetoNat/OdetoNat/1.png",
    title: "Ode to Nat",
    author: "Jessica Moon",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/reine.vizcarra/viz/PlanyourNorthernBCroadtrip/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Pl/PlanyourNorthernBCroadtrip/Dashboard1/1.png",
    title: "Plan your Northen BC roadtrip!",
    author: "Reine Vizcarra",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/guoyanming/viz/IronViz2021-ConcertToursIHaveSeen/Main",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021-ConcertToursIHaveSeen/Main/1.png",
    title: "Iron Viz 2021 - Concert Tours I Have Seen",
    author: "yanming guo",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/pierre.guenette/viz/calgarymigration-public/CanadianCitiesMigration",
    full_image:
      "https://public.tableau.com/static/images/ca/calgarymigration-public/CanadianCitiesMigration/1.png",
    title: "2018/19 Canadian Cities Migration",
    author: "Pierre Guenette",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/katie.smith/viz/Puzzles_IronViz/puzzle",
    full_image:
      "https://public.tableau.com/static/images/Pu/Puzzles_IronViz/puzzle/1.png",
    title: "Puzzles_IronViz",
    author: "Katie Smith",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/craig.heard/viz/DefyingtheOdds/DefyingtheOdds",
    full_image:
      "https://public.tableau.com/static/images/De/DefyingtheOdds/DefyingtheOdds/1.png",
    title: "Defying the Odds",
    author: "Craig Heard",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lalijularbal/viz/RwandaViz2_0/OVERVIEW",
    full_image:
      "https://public.tableau.com/static/images/Rw/RwandaViz2_0/OVERVIEW/1.png",
    title: "Rwanda",
    author: "Lali Jularbal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yuri.wg/viz/IronViz2021ChineseCalligraphy/ChineseCalligraphy",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021ChineseCalligraphy/ChineseCalligraphy/1.png",
    title: "Chinese Calligraphy",
    author: "Yuli Wang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/arshad.ejaz/viz/MakeoverMondayWeek022021Viz5GenderInequalityinHIVInfectionsinAdolescents/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ma/MakeoverMondayWeek022021Viz5GenderInequalityinHIVInfectionsinAdolescents/Dashboard1/1.png",
    title: "GENDER DISPARITIES IN ADOLESCENT HIV",
    author: "Arshad Ejaz",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mauricio.az.lgara/viz/TableauCompetition_16232504270450/Story1",
    full_image:
      "https://public.tableau.com/static/images/Ta/TableauCompetition_16232504270450/Story1/1.png",
    title: "Internet facts",
    author: "Mauricio AZALGARA",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/david.francois/viz/AnemiainTrinidadandTobagowomenandchildren/DashboardAnemia",
    full_image:
      "https://public.tableau.com/static/images/An/AnemiainTrinidadandTobagowomenandchildren/DashboardAnemia/1.png",
    title: "Anemia in women and children",
    author: "David Francois",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chaubey.shubham/viz/Bollywood_Analysis/Main",
    full_image:
      "https://public.tableau.com/static/images/Bo/Bollywood_Analysis/Main/1.png",
    title: "Bollywood- Indian Cinema",
    author: "Shubham Chaubey",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ourweaves/viz/DIYPersonalWealthManagement/India",
    full_image:
      "https://public.tableau.com/static/images/DI/DIYPersonalWealthManagement/India/1.png",
    title: "DIY Personal Wealth Management | Indian Market",
    author: "Our Weaves",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/katie.kilroy/viz/TUNES-IronViz2021/LATESTVERSION",
    full_image:
      "https://public.tableau.com/static/images/TU/TUNES-IronViz2021/LATESTVERSION/1.png",
    title: "TUNES",
    author: "Katie Kilroy",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yash.rao/viz/WEEK191GBofmobiledataavg_costineverycountry/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/WE/WEEK191GBofmobiledataavg_costineverycountry/Dashboard1/1.png",
    title: "1GB of mobile data avg. cost in every country",
    author: "yash rao",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/westlake.cjw/viz/UKSunshineRain/SunshineandRain",
    full_image:
      "https://public.tableau.com/static/images/UK/UKSunshineRain/SunshineandRain/1.png",
    title:
      "If you want to enjoy the sunshine, you have to get through the rain",
    author: "Chris Westlake",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/nilesh.jadhav7491/viz/CoronavirusCOVID-19Cases_16228584076620/COVID-19UnitedStatesView",
    full_image:
      "https://public.tableau.com/static/images/Co/CoronavirusCOVID-19Cases_16228584076620/COVID-19UnitedStatesView/1.png",
    title: "Coronavirus ( covid cases)-19",
    author: "NILESH JADHAV",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/indre7434/viz/FoodandWinePairing/WineandFoodPairing",
    full_image:
      "https://public.tableau.com/static/images/Fo/FoodandWinePairing/WineandFoodPairing/1.png",
    title: "Food and Wine Pairing",
    author: "Indre Liutkus",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ryansoares/viz/TenSecondsofGlory/Intro",
    full_image:
      "https://public.tableau.com/static/images/Te/TenSecondsofGlory/Intro/1.png",
    title: "Ten Seconds of Glory",
    author: "Ryan Soares",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/zainab2225/viz/TheSoundofMusicIronViz/SoundofMusic",
    full_image:
      "https://public.tableau.com/static/images/Th/TheSoundofMusicIronViz/SoundofMusic/1.png",
    title: "The Sound of Music",
    author: "Zainab Ayodimeji",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/paul.mago/viz/RupaulsDragRaceSeason13/Home",
    full_image:
      "https://public.tableau.com/static/images/Ru/RupaulsDragRaceSeason13/Home/1.png",
    title: "Rupaul's Drag Race Season 13 #WinnersCircle vs #PorkChops",
    author: "Paul Mago",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/wjsutton/viz/WeDidntStartTheFireIronViz2021/TheFire",
    full_image:
      "https://public.tableau.com/static/images/We/WeDidntStartTheFireIronViz2021/TheFire/1.png",
    title: "We Didn't Start The Fire",
    author: "Will Sutton",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/simon.rowe/viz/EnglandsFootballHistory/MainDashboard",
    full_image:
      "https://public.tableau.com/static/images/En/EnglandsFootballHistory/MainDashboard/1.png",
    title: "Englands Football History",
    author: "Simon Rowe",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/casey.scully5711/viz/RDUTUGVizContestCaseyScully/WhoControlsTheAirTraffic",
    full_image:
      "https://public.tableau.com/static/images/RD/RDUTUGVizContestCaseyScully/WhoControlsTheAirTraffic/1.png",
    title: "Who Controls The Air Traffic",
    author: "Casey Scully",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jusdespommes/viz/IronVizFeederEMD/IronvizFeederEMD",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizFeederEMD/IronvizFeederEMD/1.png",
    title: "EMD",
    author: "Alex Jones",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/virginia.moench/viz/Soundtrackofmyfirst40years/MySoundtrack",
    full_image:
      "https://public.tableau.com/static/images/So/Soundtrackofmyfirst40years/MySoundtrack/1.png",
    title: "Soundtrack of my First 40 Years",
    author: "Virginia Moench",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/naveen.mithare/viz/ColorfulStories/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/Co/ColorfulStories/Dashboard/1.png",
    title: "Colorful Stories",
    author: "Naveen Mithare",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ant.pulley/viz/FleetvilleSwingBand-10Yearsand100GigsIronViz2021/FSB",
    full_image:
      "https://public.tableau.com/static/images/Fl/FleetvilleSwingBand-10Yearsand100GigsIronViz2021/FSB/1.png",
    title: "Fleetville Swing Band - 10 Years and 100 Gigs (IronViz2021)",
    author: "Ant Pulley",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kchuphone/viz/JoyofWordsTreesofCharacters-IronViz2021/TreesofCharacters",
    full_image:
      "https://public.tableau.com/static/images/Jo/JoyofWordsTreesofCharacters-IronViz2021/TreesofCharacters/1.png",
    title: "Joy of Words | The Trees of Characters",
    author: "Kathy Chan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/gayane.gharajyan/viz/ArtificialMartianswheretofindthem/ArtificialMartianswheretofindthem",
    full_image:
      "https://public.tableau.com/static/images/Ar/ArtificialMartianswheretofindthem/ArtificialMartianswheretofindthem/1.png",
    title: "Artificial Martians & where to find them",
    author: "Gayane Gharajyan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kevin.kretzmer/viz/NFLDraftAnalysisIronViz2021/NFLDraftAnalysis",
    full_image:
      "https://public.tableau.com/static/images/NF/NFLDraftAnalysisIronViz2021/NFLDraftAnalysis/1.png",
    title: "NFL Draft Analysis",
    author: "Kevin Kretzmer",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/k.aditya.srinivas7711/viz/Kolkata-TheCityofJoy/THECITYOFJOY",
    full_image:
      "https://public.tableau.com/static/images/Ko/Kolkata-TheCityofJoy/THECITYOFJOY/1.png",
    title: "Kolkata - The City of Joy",
    author: "K Aditya Srinivas",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yangzhao/viz/JoyExplicitlyV3/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/Jo/JoyExplicitlyV3/Dashboard/1.png",
    title: "Joy Explicitly",
    author: "Yang Zhao",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mariona.banyeres/viz/IronViz2021TheNYT1BestSellers/NYTBestSellers",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021TheNYT1BestSellers/NYTBestSellers/1.png",
    title: "The NYT #1 Best Sellers",
    author: "Mariona Banyeres",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ivan.mf/viz/CRYPTO-FEAST/Crypto-Feast",
    full_image:
      "https://public.tableau.com/static/images/CR/CRYPTO-FEAST/Crypto-Feast/1.png",
    title: "CRYPTO-FEAST",
    author: "Iv\u00e1n Mateo Forc\u00e9n",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/anant.jyot.sinha/viz/BrooklynNineNineTitleofyourSexTapeGags/TOST",
    full_image:
      "https://public.tableau.com/static/images/Br/BrooklynNineNineTitleofyourSexTapeGags/TOST/1.png",
    title: 'Brooklyn Nine Nine "Title of your Sex Tape" Gags',
    author: "ANANT SINHA",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/young.song/viz/IronViz_Aromatics_and_microbes/Aromatics_and_microbes",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz_Aromatics_and_microbes/Aromatics_and_microbes/1.png",
    title: "Aromatics and microbes",
    author: "Young Song",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/abigail.smyth/viz/PeekInsideOurGarden/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Pe/PeekInsideOurGarden/Dashboard1/1.png",
    title: "Peek Inside Our Garden",
    author: "Abigail Smyth",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lisa.rapp/viz/ComeFromAway/WelcometotheRock",
    full_image:
      "https://public.tableau.com/static/images/Co/ComeFromAway/WelcometotheRock/1.png",
    title: "Welcome to the Rock",
    author: "Lisa Rapp",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/keliddle/viz/DyingtoLive/DyingtoLive",
    full_image:
      "https://public.tableau.com/static/images/Dy/DyingtoLive/DyingtoLive/1.png",
    title: "Dying to Live",
    author: "Kathryn Liddle",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/oliviero.ferrari/viz/pizza_16244818582440/Pizza",
    full_image:
      "https://public.tableau.com/static/images/pi/pizza_16244818582440/Pizza/1.png",
    title: "Happiness in a Pizza",
    author: "Oliviero Ferrari",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/naresh.suglani/viz/SennavsProstTheGreatestRivalryinF1History/SennavsProst",
    full_image:
      "https://public.tableau.com/static/images/Se/SennavsProstTheGreatestRivalryinF1History/SennavsProst/1.png",
    title: "Senna vs Prost - The Greatest Rivalry in F1 History",
    author: "Naresh Suglani",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yvonne/viz/HowIsTheOceansHealth/HowIsTheOceansHealth",
    full_image:
      "https://public.tableau.com/static/images/Ho/HowIsTheOceansHealth/HowIsTheOceansHealth/1.png",
    title: "How Is The Oceans' Health?",
    author: "Yvonne Beirne FitzGerald",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/amy.r.wu/viz/IronViz1_16244944094090/Austin_TUGs_Online",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz1_16244944094090/Austin_TUGs_Online/1.png",
    title: "Austin TUGs Online",
    author: "Amy Wu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shankarramar/viz/MakeOverMonday2020Week52Globalimportofcocoabeans/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ma/MakeOverMonday2020Week52Globalimportofcocoabeans/Dashboard1/1.png",
    title: "Global Import Of Cocoa Beans",
    author: "Shankar Ramar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/pradnya.patil3254/viz/StoryofHindustaniClassicalMusic/StoryofHindustaniClassicalMusic",
    full_image:
      "https://public.tableau.com/static/images/St/StoryofHindustaniClassicalMusic/StoryofHindustaniClassicalMusic/1.png",
    title: "Story of Hindustani Classical Music",
    author: "Pradnya Patil",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chi.huang/viz/12BEST-SELLINGFOUNDATIONBRANDSMORESHADESMOREINCLUSIVE/FOUNDATIONSHADES",
    full_image:
      "https://public.tableau.com/static/images/12/12BEST-SELLINGFOUNDATIONBRANDSMORESHADESMOREINCLUSIVE/FOUNDATIONSHADES/1.png",
    title:
      "MORE SHADES \u2260 MORE INCLUSIVE  (12 BEST-SELLING FOUNDATION BRANDS )",
    author: "chi huang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ruben.vdv/viz/IronViz2021Qualifier/IronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021Qualifier/IronViz2021/1.png",
    title: "Power creep in the Pok\u00e9mon video games",
    author: "Ruben Van de Voorde",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/syed.nehal/viz/Book1_16250621381780/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Bo/Book1_16250621381780/Dashboard1/1.png",
    title: "Sales Data Analysis_Superstore",
    author: "Syed Nehal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chaitra7846/viz/IronViz2021_16252885077360/IronViz_2021",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021_16252885077360/IronViz_2021/1.png",
    title: "Love for Cricket",
    author: "Chaitra Ananth",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/claire.zhang5282/viz/APandemicGolferNarrativeIronViz/APandemicGolferNarrative2",
    full_image:
      "https://public.tableau.com/static/images/AP/APandemicGolferNarrativeIronViz/APandemicGolferNarrative2/1.png",
    title: "Understanding Golf - A Pandemic Golfer Narrative",
    author: "Claire Zhang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jun.yan.yeo/viz/SkillsJobs/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Sk/SkillsJobs/Dashboard1/1.png",
    title: "Global Skill Stocktake 2015-2019",
    author: "Jun Yan Yeo",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rafael.sierra/viz/IV2021-RS-v1/IronViz2021-Totrainortodine",
    full_image:
      "https://public.tableau.com/static/images/IV/IV2021-RS-v1/IronViz2021-Totrainortodine/1.png",
    title: "To train or to dine?",
    author: "Rafael Sierra",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/julie.neble/viz/PictureBooks/FrontPage",
    full_image:
      "https://public.tableau.com/static/images/Pi/PictureBooks/FrontPage/1.png",
    title: "Picture Books",
    author: "Julie Neble",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/cj.mayes/viz/ChildhoodHero-IronViz2021/IronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Ch/ChildhoodHero-IronViz2021/IronViz2021/1.png",
    title: "Childhood Hero",
    author: "Cj Mayes",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/john.whitmer/viz/PitchPerfect_16246695615710/PitchPerfect",
    full_image:
      "https://public.tableau.com/static/images/Pi/PitchPerfect_16246695615710/PitchPerfect/1.png",
    title: "Pitch Perfect",
    author: "John Whitmer",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rincon/viz/Whatbringsjoyinmylife/CardGames",
    full_image:
      "https://public.tableau.com/static/images/Wh/Whatbringsjoyinmylife/CardGames/1.png",
    title: "What brings joy in my life?",
    author: "annabelle rincon",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/vignesh.suresh1214/viz/WWEChampionsIronViz2021/WWEChampions",
    full_image:
      "https://public.tableau.com/static/images/WW/WWEChampionsIronViz2021/WWEChampions/1.png",
    title: "WWE Champions",
    author: "Vignesh  Suresh",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rizal.bahrul.ulum6588/viz/HajiVizJatimOpsi2/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ha/HajiVizJatimOpsi2/Dashboard1/1.png",
    title: "Hajj Registration (East Java - Indonesia, 2019)",
    author: "Rizal Bahrul Ulum",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/anne.sophie5083/viz/Six_reasons_to_watch_Buffy/BUFFY",
    full_image:
      "https://public.tableau.com/static/images/Si/Six_reasons_to_watch_Buffy/BUFFY/1.png",
    title: "Six reasons to (re)watch Buffy the Vampire Slayer",
    author: "Anne-Sophie Pereira De Sa",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/anya.schwartz/viz/QUEERYABOOKS/QueerYABooks",
    full_image:
      "https://public.tableau.com/static/images/QU/QUEERYABOOKS/QueerYABooks/1.png",
    title: "Diversity in Queer Young Adult Books",
    author: "Anya Schwartz",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chloe.ma6950/viz/Risklevelchangesinspeciesincanadafrom2005to2015/RiskLevelChangesinSpeciesinCanadafrom2005to2015",
    full_image:
      "https://public.tableau.com/static/images/Ri/Risklevelchangesinspeciesincanadafrom2005to2015/RiskLevelChangesinSpeciesinCanadafrom2005to2015/1.png",
    title: "Risk Level Tracker: Species in Canada 2005-2015",
    author: "Chloe Ma",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/alanliow/viz/HawkerChiakHoLiaoATributetoSingaporesHawkerCulture/HawkerChiakHoLiao",
    full_image:
      "https://public.tableau.com/static/images/Ha/HawkerChiakHoLiaoATributetoSingaporesHawkerCulture/HawkerChiakHoLiao/1.png",
    title:
      "A Tribute to Singapore's Hawker Culture - Hawker Chiak Ho Liao (Eat Good Food)!",
    author: "Alan Liow",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sivaramakrishna.yerramsetti/viz/ForTheLoveofAviation/ForTheLoveofAviation",
    full_image:
      "https://public.tableau.com/static/images/Fo/ForTheLoveofAviation/ForTheLoveofAviation/1.png",
    title: "For The Love of Aviation",
    author: "Sivaramakrishna Yerramsetti",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/whitney6892/viz/whisky1/Dashboard6",
    full_image:
      "https://public.tableau.com/static/images/wh/whisky1/Dashboard6/1.png",
    title: "Great Scotch! A look at Scotch's Record Breaking Year",
    author: "Whitney Wood",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/opx4052/viz/Book2_16233831431630/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Bo/Book2_16233831431630/Dashboard1/1.png",
    title: "IronViz 2021 | What I have watched on Netflix",
    author: "Pei Xuan Ong",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/martin.van.drunen/viz/BelgianGrandPrix/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Be/BelgianGrandPrix/Dashboard1/1.png",
    title: "Belgian Grand Prix",
    author: "Martin van Drunen",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jennifer.dawes/viz/RememberingTheWizardofOz/RememberingTheWizardofOz",
    full_image:
      "https://public.tableau.com/static/images/Re/RememberingTheWizardofOz/RememberingTheWizardofOz/1.png",
    title: "Remembering The Wizard of Oz",
    author: "Jennifer Dawes",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/solomont/viz/fish_16252499307540/fish",
    full_image:
      "https://public.tableau.com/static/images/fi/fish_16252499307540/fish/1.png",
    title: "fish",
    author: "Solomon T",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/tina1167/viz/ArmyoftheDead/ArmyoftheDead",
    full_image:
      "https://public.tableau.com/static/images/Ar/ArmyoftheDead/ArmyoftheDead/1.png",
    title: "Army of the Dead (Spoiler Alert)",
    author: "Tina Covelli",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jake.neal3957/viz/NFLDash2/ExpandingtheNFL",
    full_image:
      "https://public.tableau.com/static/images/NF/NFLDash2/ExpandingtheNFL/1.png",
    title: "NFL Expansion",
    author: "Jake Neal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mateusz.karmalski/viz/LegoTechnicironviz/start",
    full_image:
      "https://public.tableau.com/static/images/Le/LegoTechnicironviz/start/1.png",
    title: "Technic - history of unique Lego theme",
    author: "Mateusz Karmalski",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lilla.rasztik/viz/IronVizDatafamInspirationJOY/Whatbringsmejoy",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizDatafamInspirationJOY/Whatbringsmejoy/1.png",
    title: "#Datafam + Inspiration = JOY",
    author: "Lilla Rasztik",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/cheryl.tan4120/viz/IronVizTheHistoryofKpop/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizTheHistoryofKpop/Dashboard/1.png",
    title: "The History of Kpop",
    author: "Cheryl Tan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/grectech/viz/CompareTornadoDamageandMobileHomePerState/Story2",
    full_image:
      "https://public.tableau.com/static/images/Co/CompareTornadoDamageandMobileHomePerState/Story2/1.png",
    title: "Tornado Damage and Mobile Homes",
    author: "Chris Greco",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/michiyo2970/viz/Inflationforecast_16213499858440/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/In/Inflationforecast_16213499858440/Dashboard1/1.png",
    title: "Price Indices",
    author: "Michiyo Hayakawa",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jayshree.dawrewar/viz/IronViz2021DataJoyLifeDuringLockdown/IronViz2021DataJoyLifeDuringLockdown",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021DataJoyLifeDuringLockdown/IronViz2021DataJoyLifeDuringLockdown/1.png",
    title: "Life During Lockdown",
    author: "Jayshree Dawrewar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chimdi.nwosu/viz/IronViz2021-NewJackSwing/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021-NewJackSwing/Dashboard1/1.png",
    title: "Welcome to Planet New Jack Swing - Music to Make You Dance",
    author: "Chimdi Nwosu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/cameron1133/viz/WhoistheGreatestRacerIronViz2021/WhoistheGreatestRacer",
    full_image:
      "https://public.tableau.com/static/images/Wh/WhoistheGreatestRacerIronViz2021/WhoistheGreatestRacer/1.png",
    title: "Who is the Greatest Racer? #IronViz2021",
    author: "Cameron Halsted",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/trey.w./viz/Working_cultivation_dashboard/Map",
    full_image:
      "https://public.tableau.com/static/images/Wo/Working_cultivation_dashboard/Map/1.png",
    title: "MA Cannabis Market Tracker",
    author: "Trey Williams",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yash.sakhuja/viz/IronViz2021-BOOKWORM/IronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021-BOOKWORM/IronViz2021/1.png",
    title: "Bookworm: Insights into my Goodreads account",
    author: "Yash Sakhuja",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/brrosenau/viz/WORKINPROGRESS_16252510831320/99Waffles2",
    full_image:
      "https://public.tableau.com/static/images/WO/WORKINPROGRESS_16252510831320/99Waffles2/1.png",
    title: "10 Years of 99% Invisible",
    author: "Brittany Rosenau",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/adrian.zinovei/viz/IronViz2021_16249289945530/IronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021_16249289945530/IronViz2021/1.png",
    title: "Pandemic Year",
    author: "ADRIAN ZINOVEI",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/debbie.andre/viz/JoyofNHLHockey/NHLReviewbyGame",
    full_image:
      "https://public.tableau.com/static/images/Jo/JoyofNHLHockey/NHLReviewbyGame/1.png",
    title: "Joy of NHL Hockey",
    author: "Debbie Andre",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/vaishnavi.patil1715/viz/InsightsonNutritionalFactsinMcDonaldsMenu/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/In/InsightsonNutritionalFactsinMcDonaldsMenu/Dashboard1/1.png",
    title: "Insights on Nutritional Facts in McDonald's Menu",
    author: "Vaishnavi Patil",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ilena/viz/Chopin/ChopinEtudes",
    full_image:
      "https://public.tableau.com/static/images/Ch/Chopin/ChopinEtudes/1.png",
    title: "A Study of Chopin's \u00c9tudes",
    author: "Ilena Peng",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/aashiq.shiwaxx/viz/TheMilestone10K/MILESTONE10K",
    full_image:
      "https://public.tableau.com/static/images/Th/TheMilestone10K/MILESTONE10K/1.png",
    title: "The Milestone 10K! | #IronViz 2021",
    author: "Aashiq Shiwazz",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/monika.majewska/viz/MoviesCookbookNEW/Movies_Cookbook",
    full_image:
      "https://public.tableau.com/static/images/Mo/MoviesCookbookNEW/Movies_Cookbook/1.png",
    title: "Movies Cookbook",
    author: "Monika Majewska",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shruti.nikhila.gupta/viz/MavenKickstarterProjectChallenge/KickstarterProjects-CrowdfundingAnalysis",
    full_image:
      "https://public.tableau.com/static/images/Ma/MavenKickstarterProjectChallenge/KickstarterProjects-CrowdfundingAnalysis/1.png",
    title: "KickstarterProjects - CrowdfundingAnalysis",
    author: "Shruti Gupta",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jasmine.boatner/viz/broadwayironviz/Broadway",
    full_image:
      "https://public.tableau.com/static/images/br/broadwayironviz/Broadway/1.png",
    title: "Broadway Finances",
    author: "Jasmine Boatner",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rosie.st.germain/viz/DarkChocolateyGoodness/DarkChocolateyGoodness",
    full_image:
      "https://public.tableau.com/static/images/Da/DarkChocolateyGoodness/DarkChocolateyGoodness/1.png",
    title: "Dark Chocolatey Goodness",
    author: "Rosie St. Germain",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/michelle.frayman/viz/IV2021-TheJoyofManyThings/TheJoyofManyThings",
    full_image:
      "https://public.tableau.com/static/images/IV/IV2021-TheJoyofManyThings/TheJoyofManyThings/1.png",
    title: "The Joy of Many Things",
    author: "Michelle Frayman",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/joti.gautam/viz/MusicInstrumentsInBooksIronviz2021_16251698491820/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Mu/MusicInstrumentsInBooksIronviz2021_16251698491820/Dashboard1/1.png",
    title: "Music Instruments in Books",
    author: "Joti Gautam",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/pavan8184/viz/AnAnalysisofRunningActivity/Running",
    full_image:
      "https://public.tableau.com/static/images/An/AnAnalysisofRunningActivity/Running/1.png",
    title: "An Analysis of Running Activity",
    author: "Pavan Kumar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sebasti.n.apablaza/viz/ElectionDayLiveStatusofPollingCentersChileLasCondes/MesasInstaladasGOB",
    full_image:
      "https://public.tableau.com/static/images/El/ElectionDayLiveStatusofPollingCentersChileLasCondes/MesasInstaladasGOB/1.png",
    title: "Election Day: Live Status of Polling Centers (Chile, Las Condes)",
    author: "SEBASTIAN APABLAZA",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jason.yang6304/viz/LostinTrending/LostinTrending",
    full_image:
      "https://public.tableau.com/static/images/Lo/LostinTrending/LostinTrending/1.png",
    title: "Lost in Trending",
    author: "Jason Yang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/piotr.szczes/viz/Exoplanets_16252591758790/ExoplanetsSearchforthesecondEarth",
    full_image:
      "https://public.tableau.com/static/images/Ex/Exoplanets_16252591758790/ExoplanetsSearchforthesecondEarth/1.png",
    title: "Exoplanets: Searching for a second Earth",
    author: "Piotr Szczes",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/charan.tej.mandali3205/viz/TravelTourism-SmartInternz/TravelTourismDashboard",
    full_image:
      "https://public.tableau.com/static/images/Tr/TravelTourism-SmartInternz/TravelTourismDashboard/1.png",
    title: "Travel & Tourism in India(2016-2017)",
    author: "Charan Tej Mandali",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/victor.clarke/viz/CareerPathsAustralianPMs/CareerPaths",
    full_image:
      "https://public.tableau.com/static/images/Ca/CareerPathsAustralianPMs/CareerPaths/1.png",
    title: "Career Paths: Australian Prime Ministers 1970-2021",
    author: "Victor Clarke",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/oana.tudorancea/viz/AliceWaters/AliceWaters",
    full_image:
      "https://public.tableau.com/static/images/Al/AliceWaters/AliceWaters/1.png",
    title: "Alice Waters: How we eat can change the world",
    author: "Oana Tudorancea",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/thi.ho/viz/IronViz20211YearOfNetflixJoyInSolitudeOrNot/NetflixJoy",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz20211YearOfNetflixJoyInSolitudeOrNot/NetflixJoy/1.png",
    title: "1 Year Of Netflix Joy In Solitude (Or Not?)",
    author: "Thi Ho",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/victor.luu1047/viz/HowWeKeepTheLightsOn/TheWorldWeLiveIn",
    full_image:
      "https://public.tableau.com/static/images/Ho/HowWeKeepTheLightsOn/TheWorldWeLiveIn/1.png",
    title: "How We Keep The Lights On",
    author: "Victor Luu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/matt.martin7868/viz/Second-HandShoppingistheNewBlack/Second-HandShoppingistheNewBlack",
    full_image:
      "https://public.tableau.com/static/images/Se/Second-HandShoppingistheNewBlack/Second-HandShoppingistheNewBlack/1.png",
    title: "Second-Hand Shopping is the New Black",
    author: "Matt Martin",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/martina6444/viz/VizWhatyouLove_HarryPotterCharacters/HarryPotterDashboard",
    full_image:
      "https://public.tableau.com/static/images/Vi/VizWhatyouLove_HarryPotterCharacters/HarryPotterDashboard/1.png",
    title: "Harry Potter and the characters who shape the wizarding world",
    author: "Maggy Muellner",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kimly.scott/viz/ironviz2021cominghomedraft/ironviz2021cominghome",
    full_image:
      "https://public.tableau.com/static/images/ir/ironviz2021cominghomedraft/ironviz2021cominghome/1.png",
    title: "coming home",
    author: "Kim Ly Scott",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jared8766/viz/PorterRobinson/PorterRobinson3",
    full_image:
      "https://public.tableau.com/static/images/Po/PorterRobinson/PorterRobinson3/1.png",
    title: "A look at the Career of Porter Robinson",
    author: "Jared Flores",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/robradburn/viz/radio6byday/CantGetYouOutofMyHead",
    full_image:
      "https://public.tableau.com/static/images/ra/radio6byday/CantGetYouOutofMyHead/1.png",
    title: "Can't Get You Out of My Head",
    author: "Robert Radburn",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shreyashsingh1/viz/Scooby-DooWhereAreYou/Scooby-Doo",
    full_image:
      "https://public.tableau.com/static/images/Sc/Scooby-DooWhereAreYou/Scooby-Doo/1.png",
    title: "Scooby-Doo, Where Are You!",
    author: "Shreyash Singh",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/huong.parrain/viz/BestCountriesattheOlympicGames/White-Theme-OG",
    full_image:
      "https://public.tableau.com/static/images/Be/BestCountriesattheOlympicGames/White-Theme-OG/1.png",
    title: "Best Countries at the Olympic Games",
    author: "Anathan Huong-Parrain",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/manirakiza/viz/last_16224022353450/Production_grip_org",
    full_image:
      "https://public.tableau.com/static/images/la/last_16224022353450/Production_grip_org/1.png",
    title:
      "Belgium's Leading armament companies - L' industrie de l'armement Belge",
    author: "Marie-Jo Manirakiza",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kruthikha10/viz/ARRahmanscontributiontotheworldofMusic/ARR",
    full_image:
      "https://public.tableau.com/static/images/AR/ARRahmanscontributiontotheworldofMusic/ARR/1.png",
    title: "A R Rahman's Contribution to the world of Music",
    author: "Kruthikha Sankaranarayanan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mokwinski/viz/MaltWhiskyLandscape/MaltWhiskyLandscape",
    full_image:
      "https://public.tableau.com/static/images/Ma/MaltWhiskyLandscape/MaltWhiskyLandscape/1.png",
    title: "Malt Whisky Landscape",
    author: "Michal Mokwinski",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/vineeth.vijayakumar/viz/Forbes_Richest_Football_Clubs/FootBallMoneyLeauge",
    full_image:
      "https://public.tableau.com/static/images/Fo/Forbes_Richest_Football_Clubs/FootBallMoneyLeauge/1.png",
    title: "Football Money League",
    author: "Vineeth  V",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/aloysius.ong/viz/AloysiusandMoviesIronViz2021/IronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Al/AloysiusandMoviesIronViz2021/IronViz2021/1.png",
    title: "Aloysius' Movies",
    author: "Aloysius Ong",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jacqui.moore/viz/HotWheels_16250929544350/HotWheels",
    full_image:
      "https://public.tableau.com/static/images/Ho/HotWheels_16250929544350/HotWheels/1.png",
    title: "Those are Some Hot Wheels!",
    author: "Jacqueline Moore",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/joycelyn.iloreta/viz/FriendsandOutdoors/Dashboard2",
    full_image:
      "https://public.tableau.com/static/images/Fr/FriendsandOutdoors/Dashboard2/1.png",
    title: "Friends and Outdoors",
    author: "JOYCELYN ILORETA",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/dinushki.de.livera/viz/TheGreatBritishBakeOff/GBBO",
    full_image:
      "https://public.tableau.com/static/images/Th/TheGreatBritishBakeOff/GBBO/1.png",
    title: "The Great British Bake Off",
    author: "Dinushki De Livera",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sudipto.mitra/viz/DashboardKeyword_Search_Analysis/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Da/DashboardKeyword_Search_Analysis/Dashboard1/1.png",
    title: "Dashboard: Keyword_Search_Analysis",
    author: "Sudipto Mitra",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ray.lee/viz/J-POP-KenshiYonezu/KenshiYonezu",
    full_image:
      "https://public.tableau.com/static/images/J-/J-POP-KenshiYonezu/KenshiYonezu/1.png",
    title: "J-POP - Kenshi Yonezu",
    author: "Ray Lee",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/prithak.k.b/viz/TheCocktailCornersoftheWorld_IronViz_2021/TheCocktailCornersoftheWorld_Data",
    full_image:
      "https://public.tableau.com/static/images/Th/TheCocktailCornersoftheWorld_IronViz_2021/TheCocktailCornersoftheWorld_Data/1.png",
    title: "The Cocktail Corners of the World_#IronViz_2021",
    author: "Prithak Bhattacharyya",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/saveriorocchetti/viz/TreeHealth-IronViz2021/TreeHealth",
    full_image:
      "https://public.tableau.com/static/images/Tr/TreeHealth-IronViz2021/TreeHealth/1.png",
    title: "Tree Health",
    author: "Saverio Rocchetti",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/benmangel/viz/NETFLIXANALYSISFINAL/FinalDashboard",
    full_image:
      "https://public.tableau.com/static/images/NE/NETFLIXANALYSISFINAL/FinalDashboard/1.png",
    title: "Netflix Analysis",
    author: "Ben Mangel",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/bingzhen.ma/viz/AcadiaNationalPark/AcadiaNationalPark",
    full_image:
      "https://public.tableau.com/static/images/Ac/AcadiaNationalPark/AcadiaNationalPark/1.png",
    title: "Acadia National Park",
    author: "Ben Ma",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/toshiya.kijima6210/viz/BTSasTop1inBillboardIronViz2021/viz",
    full_image:
      "https://public.tableau.com/static/images/BT/BTSasTop1inBillboardIronViz2021/viz/1.png",
    title: "BTS as Top1 in Billboard",
    author: "Toshiya Kijima",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/pallav.shah/viz/Ironviz2021FootballandCristianoRonaldo_16251478090490/CristianoRonaldo",
    full_image:
      "https://public.tableau.com/static/images/Ir/Ironviz2021FootballandCristianoRonaldo_16251478090490/CristianoRonaldo/1.png",
    title: "#Ironviz2021: Football, Cristiano Ronaldo and Euro 2020",
    author: "Pallav  Shah",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/aur.lien8073/viz/Music_Over_The_Time/Musicoverthetime",
    full_image:
      "https://public.tableau.com/static/images/Mu/Music_Over_The_Time/Musicoverthetime/1.png",
    title: "Music Over The Time",
    author: "Aur\u00e9lien Martinez",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/dennis.kao/viz/IronViz-TheJoyofTeaching/Main",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz-TheJoyofTeaching/Main/1.png",
    title:
      "The Joy of Teaching Data Visualization with Tableau (For the First Time)",
    author: "Dennis Kao",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/michael.dunphy8764/viz/TheJoyofLEGO-IronViz2021/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/Th/TheJoyofLEGO-IronViz2021/Dashboard/1.png",
    title: "The Joy of LEGO",
    author: "Michael Dunphy",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ssgsdg/viz/OverallHDBResalePrice/PriceMain",
    full_image:
      "https://public.tableau.com/static/images/Ov/OverallHDBResalePrice/PriceMain/1.png",
    title: "HDB Resale Transaction Price",
    author: "ALEX SEO",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sarah.moore8639/viz/GreatHeronsofourWorld2/GreatHeronsofourWorld",
    full_image:
      "https://public.tableau.com/static/images/Gr/GreatHeronsofourWorld2/GreatHeronsofourWorld/1.png",
    title: "Great Herons of Our World",
    author: "Sarah Moore",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lisa.trescott/viz/RuPaulsDragRaceLipSyncs/LipSyncs",
    full_image:
      "https://public.tableau.com/static/images/Ru/RuPaulsDragRaceLipSyncs/LipSyncs/1.png",
    title: "RuPaul's Drag Race Lip Syncs",
    author: "Lisa Trescott",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ghafar.shah2168/viz/IRONVIZ2021Marvelvs_DCFilms/IRONVIZ2021Marvelvs_DCComics",
    full_image:
      "https://public.tableau.com/static/images/IR/IRONVIZ2021Marvelvs_DCFilms/IRONVIZ2021Marvelvs_DCComics/1.png",
    title: "MARVEL VS DC COMICS",
    author: "Ghafar Shah",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ddiep/viz/IronViz2021-OlympicGames/OLYMPICGAMES",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021-OlympicGames/OLYMPICGAMES/1.png",
    title: "Olympic Games",
    author: "Dick Diep",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kimberly.clark5708/viz/InternationalTravelRecommendations/TravelRecommendations",
    full_image:
      "https://public.tableau.com/static/images/In/InternationalTravelRecommendations/TravelRecommendations/1.png",
    title: "International Travel Recommendations",
    author: "Kim Clark",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/quere1716/viz/JoyRunRunningvisionforIronViz/Ironviz",
    full_image:
      "https://public.tableau.com/static/images/Jo/JoyRunRunningvisionforIronViz/Ironviz/1.png",
    title: "JoyRun : from the training to the finish line",
    author: "Albin Qu\u00e9r\u00e9",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/michelle.hertel4428/viz/IronViz_Music/LearningtheGuitar",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz_Music/LearningtheGuitar/1.png",
    title: "No Strings Attached Music Translation",
    author: "Michelle Hertel",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/nikhil.v.nayak/viz/FifaTableauDashboard-NikhilNayak/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/Fi/FifaTableauDashboard-NikhilNayak/Dashboard/1.png",
    title: "FIFA PLAYERS AND TEAM VISUALIZATION",
    author: "Nikhil Nayak",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/harim.jung/viz/IronViz_InMemoriamVictimsfromMyanmarCoup/Timesnewroman",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz_InMemoriamVictimsfromMyanmarCoup/Timesnewroman/1.png",
    title: "In Memoriam, Victims from Myanmar Coup",
    author: "Harim Jung",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mitali.avadhani/viz/nationalparks_16250908501990/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/na/nationalparks_16250908501990/Dashboard/1.png",
    title: "National Parks ",
    author: "Mitali Avadhani",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/harish.nagpal/viz/Ever/Dashboard2",
    full_image:
      "https://public.tableau.com/static/images/Ev/Ever/Dashboard2/1.png",
    title: "Mount Everest - On top of the World",
    author: "Harish Nagpal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/saksham.taneja/viz/project4BuildDataDashboardssakshamudacity/DelayMapCausetrend",
    full_image:
      "https://public.tableau.com/static/images/pr/project4BuildDataDashboardssakshamudacity/DelayMapCausetrend/1.png",
    title: "project4 Build Data Dashboards saksham udacity by Saksham Taneja",
    author: "Saksham taneja",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chelsea.richard6461/viz/DataforLPs_CR_19Sep2019/CountyProfileDataTool",
    full_image:
      "https://public.tableau.com/static/images/Da/DataforLPs_CR_19Sep2019/CountyProfileDataTool/1.png",
    title: "County Profile Data Tool",
    author: "Chelsea Richard",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mark.corbridge/viz/CaptivatingCocktailConcoctions/CaptivatingCocktailConcoctions",
    full_image:
      "https://public.tableau.com/static/images/Ca/CaptivatingCocktailConcoctions/CaptivatingCocktailConcoctions/1.png",
    title: "Captivating Cocktail Concoctions",
    author: "Mark Corbridge",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chatchai.chattirak/viz/Doyoueatproperly_IRONVIZ_2020/Healthandwellness",
    full_image:
      "https://public.tableau.com/static/images/Do/Doyoueatproperly_IRONVIZ_2020/Healthandwellness/1.png",
    title: "DO YOU EAT PROPERLY?",
    author: "Chatchai Chattirak",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mike.levine/viz/Anti-JerFantasyFootballLeague/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/An/Anti-JerFantasyFootballLeague/Dashboard1/1.png",
    title: "Anti-Jer Fantasy Football League",
    author: "Mike Levine",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/huey.wen.perng/viz/DoesMalaysianstilllivingonthetree/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Do/DoesMalaysianstilllivingonthetree/Dashboard1/1.png",
    title: "How can Malaysian still living on the tree ?",
    author: "Sam Perng",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/priyanka.biswas/viz/Ironviz-DataJoy-Shihtzus/Shihtzu",
    full_image:
      "https://public.tableau.com/static/images/Ir/Ironviz-DataJoy-Shihtzus/Shihtzu/1.png",
    title: "Ironviz- Data+Joy- Shihtzus",
    author: "Priyanka Biswas",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/alisa.prem/viz/BTSBeyondTheSceneIronViz2021/BTSBeyondTheScene",
    full_image:
      "https://public.tableau.com/static/images/BT/BTSBeyondTheSceneIronViz2021/BTSBeyondTheScene/1.png",
    title: "BTS: Beyond The Scene",
    author: "Alisa Prem",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/nicola.windrem/viz/HistoryoftheHeisman_IronViz2021/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Hi/HistoryoftheHeisman_IronViz2021/Dashboard1/1.png",
    title: "History of the Heisman",
    author: "Nicola Windrem",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/saviohenriques/viz/LEOSatellitesSpaceRace/LEOsRacetoSpace",
    full_image:
      "https://public.tableau.com/static/images/LE/LEOSatellitesSpaceRace/LEOsRacetoSpace/1.png",
    title: "LEO satellites Space Race",
    author: "Savio Henriques",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/carrie.chen5593/viz/ForbesTaiwan-2021/1",
    full_image:
      "https://public.tableau.com/static/images/Fo/ForbesTaiwan-2021/1/1.png",
    title: "Forbes | Taiwan-2021",
    author: "YuWei Chen",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/timea.szabo/viz/birds_/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/bi/birds_/Dashboard/1.png",
    title: "The State of the UK's Water and Wetland Birds",
    author: "Timea Szabo",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/huey.wen.perng/viz/MalaysiaForestedandNonForestedDashboard/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ma/MalaysiaForestedandNonForestedDashboard/Dashboard1/1.png",
    title: "Does Malaysian still living on the tree ?",
    author: "Sam Perng",
  },
  {
    interactive:
      " https://public.tableau.com/app/profile/taofeek.hammed/viz/WalmartSuperstoreAnalysisAssignment3_16239469618450/WalmartSuperstoreAnalysisAssignment3",
    full_image:
      "https://public.tableau.com/static/images/Wa/WalmartSuperstoreAnalysisAssignment3_16239469618450/WalmartSuperstoreAnalysisAssignment3/1.png",
    title: "Walmart Superstore Business Analysis",
    author: "Taofeek Hammed",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lucia.stefanuto/viz/TwitterSocialListening/Ppal",
    full_image:
      "https://public.tableau.com/static/images/Tw/TwitterSocialListening/Ppal/1.png",
    title: "Twitter Social Media Listening",
    author: "Lucia Stefanuto",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/wai.mun.lek/viz/Groundhopping/Groundhopping",
    full_image:
      "https://public.tableau.com/static/images/Gr/Groundhopping/Groundhopping/1.png",
    title: "Groundhopping",
    author: "Wai Mun Lek",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/.88567068/viz/DataandJoyDissectingWongKarWaiFilmography/WongKarWaiFilmography",
    full_image:
      "https://public.tableau.com/static/images/Da/DataandJoyDissectingWongKarWaiFilmography/WongKarWaiFilmography/1.png",
    title: "[Data and Joy!] Dissecting Wong Kar Wai Filmography",
    author: "Juyeon Hong",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/julia8018/viz/BillboardTop1001964-2015/Overall",
    full_image:
      "https://public.tableau.com/static/images/Bi/BillboardTop1001964-2015/Overall/1.png",
    title: "Billboard Top 100 1964 - 2015",
    author: "Julia Milan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shivani.r7599/viz/Book4_16238681302470/Story1",
    full_image:
      "https://public.tableau.com/static/images/Bo/Book4_16238681302470/Story1/1.png",
    title: "Facebook ads analysis",
    author: "Shivani R",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ahilandeeswari2330/viz/BEVERAGEDRINKSANALYSIS/BeverageDrinks",
    full_image:
      "https://public.tableau.com/static/images/BE/BEVERAGEDRINKSANALYSIS/BeverageDrinks/1.png",
    title: "Starbucks Drinks Anlaysis",
    author: "Ahilandeeswari R",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mvandewinkel/viz/NeilYoungLiveConcerts/NYConcerts",
    full_image:
      "https://public.tableau.com/static/images/Ne/NeilYoungLiveConcerts/NYConcerts/1.png",
    title: "Neil Young Live Concerts",
    author: "Maarten Vandewinkel",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/roopa.shelton/viz/AlicesAdventuresinMusicland/AliceinMusicland",
    full_image:
      "https://public.tableau.com/static/images/Al/AlicesAdventuresinMusicland/AliceinMusicland/1.png",
    title: "Alice's Adventures in Musicland",
    author: "Roopa Shelton",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/monisha.m1868/viz/FinancialAnalysisofCompanies_16236795307650/FINANCIALANALYSIS",
    full_image:
      "https://public.tableau.com/static/images/Fi/FinancialAnalysisofCompanies_16236795307650/FINANCIALANALYSIS/1.png",
    title: "Financial Analysis of Companies",
    author: "Monisha M",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/william.aubrey2683/viz/Timeline_16235387301190/Timeline",
    full_image:
      "https://public.tableau.com/static/images/Ti/Timeline_16235387301190/Timeline/1.png",
    title: "Motorcycling Pandemonium",
    author: "William Aubrey",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/francisco4773/viz/NationalParksIronViz2021/NationalParks",
    full_image:
      "https://public.tableau.com/static/images/Na/NationalParksIronViz2021/NationalParks/1.png",
    title: "National Parks - Data Exploration",
    author: "Francisco  Cardoso",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kate.brown/viz/HowPublicIsTheCourse/MuniLove",
    full_image:
      "https://public.tableau.com/static/images/Ho/HowPublicIsTheCourse/MuniLove/1.png",
    title: "Muni Love",
    author: "Kathleen Brown",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shalaka.pokley/viz/RonWeasley/RonaldWeasley",
    full_image:
      "https://public.tableau.com/static/images/Ro/RonWeasley/RonaldWeasley/1.png",
    title: "Ronald Weasley: The Less Celebrated Hero",
    author: "Shalaka Pokley",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/eli.abrams/viz/MyPelotonJourneyIronViz/MyPelotonJourney",
    full_image:
      "https://public.tableau.com/static/images/My/MyPelotonJourneyIronViz/MyPelotonJourney/1.png",
    title: "My Peloton Journey",
    author: "Eli Abrams",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/boglarka.bihari/viz/TheJoyofDancingonaNeuralLevel/Thejoyofdancing",
    full_image:
      "https://public.tableau.com/static/images/Th/TheJoyofDancingonaNeuralLevel/Thejoyofdancing/1.png",
    title: "The Joy of Dancing on a Neural Level",
    author: "Boglarka Bihari",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/nabilah.rahman/viz/DisneyMulanScriptTextAnalytics/MulanTextAnalytics",
    full_image:
      "https://public.tableau.com/static/images/Di/DisneyMulanScriptTextAnalytics/MulanTextAnalytics/1.png",
    title: "Disney Mulan Script Text Analytics",
    author: "Nabilah Rahman",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jon.gubler/viz/UseofEmojisonTwitter/Emojis",
    full_image:
      "https://public.tableau.com/static/images/Us/UseofEmojisonTwitter/Emojis/1.png",
    title: "Use of Emojis on Twitter",
    author: "Jon Gubler",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/samantha.mckinlay/viz/TableauIronViz2021Entry/RuPaulsDragRaceUS",
    full_image:
      "https://public.tableau.com/static/images/Ta/TableauIronViz2021Entry/RuPaulsDragRaceUS/1.png",
    title: "RuPaul's Drag Race US",
    author: "Samantha McKinlay",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/meetali.pandey/viz/Reading-WhataNOVELIdea/Reading-WhataNOVELIdea",
    full_image:
      "https://public.tableau.com/static/images/Re/Reading-WhataNOVELIdea/Reading-WhataNOVELIdea/1.png",
    title: "Reading - What a NOVEL Idea",
    author: "Meetali Pandey",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/alan.o.reilly/viz/Spotify-AYearinReview/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Sp/Spotify-AYearinReview/Dashboard1/1.png",
    title: "Spotify - A Year in Review",
    author: "Alan O'Reilly",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/priyanka.dobhal0993/viz/IronVizThejoyofmusic/NoMusic",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizThejoyofmusic/NoMusic/1.png",
    title: "The Joy of Music",
    author: "Priyanka Dobhal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/megan.feeney6418/viz/MeganMarkleHeadlineFinal/IronViz",
    full_image:
      "https://public.tableau.com/static/images/Me/MeganMarkleHeadlineFinal/IronViz/1.png",
    title: "Megan Markle Headline Final",
    author: "Megan Feeney",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sruthi7339/viz/SruthiRavikumarJoyViz/JoyDashboard",
    full_image:
      "https://public.tableau.com/static/images/Sr/SruthiRavikumarJoyViz/JoyDashboard/1.png",
    title: "Library of Joy",
    author: "Sruthi Ravikumar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ahmed7788/viz/Book1_16233386355210/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Bo/Book1_16233386355210/Dashboard1/1.png",
    title: "Covid19 Data Visualization - Old Data",
    author: "Riaz Ahmed",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/peter.kelley4414/viz/DuvalDeepDive/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Du/DuvalDeepDive/Dashboard1/1.png",
    title: "Duval Deep Dive",
    author: "Peter Kelley",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/roshni5291/viz/Tableau-IronViz2021-TheBigBangTheory/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ta/Tableau-IronViz2021-TheBigBangTheory/Dashboard1/1.png",
    title: "It all started with a Big Bang",
    author: "Roshni Mehra",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/robbin.vernooij/viz/Whodoesntloveajoke/DataJoy",
    full_image:
      "https://public.tableau.com/static/images/Wh/Whodoesntloveajoke/DataJoy/1.png",
    title: "Who doesn't love a joke?",
    author: "Robbin Vernooij",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/dr.michael.stanley.baker/viz/BencaojingjizhuinThreeLayers/CountAddress",
    full_image:
      "https://public.tableau.com/static/images/Be/BencaojingjizhuinThreeLayers/CountAddress/1.png",
    title: "Bencaojing jizhu \u672c\u8349\u7d93\u96c6\u6ce8 in Three Layers",
    author: "Michael Stanley-Baker",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shwetabh3591/viz/IPL_Analysis_16113202052630/BowlerAnalysis",
    full_image:
      "https://public.tableau.com/static/images/IP/IPL_Analysis_16113202052630/BowlerAnalysis/1.png",
    title: "IPL Dashboard",
    author: "shwetabh Dixit",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/dhawal.mistri/viz/WhatDoes1GBofMobileDataCostinEveryCountry_16208017170830/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Wh/WhatDoes1GBofMobileDataCostinEveryCountry_16208017170830/Dashboard1/1.png",
    title: "Cost of 1GB data in every country",
    author: "Dhawal Mistri",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/d.o3024/viz/IronVizCatAdoptioninSingapore/CatAdoptioninSingaporewithCaseStudy",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizCatAdoptioninSingapore/CatAdoptioninSingaporewithCaseStudy/1.png",
    title:
      "Cat Adoption in Singapore, with Detailed Analysis for One Case Study.",
    author: "Deb O",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/wendy.shijia/viz/IronViz2021_16251429007040/TheStars",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021_16251429007040/TheStars/1.png",
    title: "The Stars",
    author: "Shijia Wang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rebeka.keri/viz/RoadToRio-WrestlingatOympics/Wrestling-RoadToTokyo",
    full_image:
      "https://public.tableau.com/static/images/Ro/RoadToRio-WrestlingatOympics/Wrestling-RoadToTokyo/1.png",
    title: "Road To Rio-Wrestling at Oympics",
    author: "Rebeka Keri",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jon.tehrani/viz/SomeLikeItHotOnes/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/So/SomeLikeItHotOnes/Dashboard/1.png",
    title: "Some Like It Hot Ones",
    author: "Jon Tehrani",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/clairestrickler/viz/TaylorSwiftSoundEvolution/TaylorSwift",
    full_image:
      "https://public.tableau.com/static/images/Ta/TaylorSwiftSoundEvolution/TaylorSwift/1.png",
    title: "Taylor Swift's Sound Evolution",
    author: "Claire Strickler",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kwill3477/viz/Viz01-Titanic_Insight/1",
    full_image:
      "https://public.tableau.com/static/images/Vi/Viz01-Titanic_Insight/1/1.png",
    title: "Memory for Titanic",
    author: "K Will",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/christine.yu3709/viz/TheJoyinAnimeAvgratingsbyGenreandNumberofEpisodes/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Th/TheJoyinAnimeAvgratingsbyGenreandNumberofEpisodes/Dashboard1/1.png",
    title: "The Joy in Anime: Average Ratings by Genre and Number of Episodes",
    author: "Christine Yu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/qianyu.zhang7274/viz/SNAPandFarmersMarket/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/SN/SNAPandFarmersMarket/Dashboard/1.png",
    title: "SNAP and Farmers Market",
    author: "Qianyu  Zhang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/vedant/viz/MarvelCinematicUniverse_16252511253050/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ma/MarvelCinematicUniverse_16252511253050/Dashboard1/1.png",
    title: "Marvel Cinematic Universe",
    author: "Vedant Sharma",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lili.surcel/viz/Whocookedmorein2021/Whocookedmorein2021",
    full_image:
      "https://public.tableau.com/static/images/Wh/Whocookedmorein2021/Whocookedmorein2021/1.png",
    title: "Who cooked more in 2021?",
    author: "Lili Surcel",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/melissapang/viz/IronViz2021_16249786512900/CoffeeQuality",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021_16249786512900/CoffeeQuality/1.png",
    title: "Coffee beans around the world",
    author: "Melissa Pang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/swapna.lonkar/viz/KishoreKumar-Ver_4/KishoreKumar",
    full_image:
      "https://public.tableau.com/static/images/Ki/KishoreKumar-Ver_4/KishoreKumar/1.png",
    title: "Kishore Kumar",
    author: "Swapna Lonkar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/adityakumbhavdekar/viz/IronViz2021_16246038289400/OldMonk-IronViz",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021_16246038289400/OldMonk-IronViz/1.png",
    title: "Old monk is Joy",
    author: "Aditya Kumbhavdekar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/karl.pillora/viz/Happiness_16252950280070/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ha/Happiness_16252950280070/Dashboard1/1.png",
    title: "Happiness",
    author: "Karl Pillora",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/oliviero.ferrari/viz/pizza_16244818582440/Pizza",
    full_image:
      "https://public.tableau.com/static/images/pi/pizza_16244818582440/Pizza/1.png",
    title: "Happiness in a Pizza",
    author: "Oliviero Ferrari",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ravi.shankar1671/viz/Reccos_2/Movie_List",
    full_image:
      "https://public.tableau.com/static/images/Re/Reccos_2/Movie_List/1.png",
    title: "Recommendation Engine-Bollywood Movies",
    author: "Ravi Shankar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/joshua.haines/viz/TheBattleofMidwayTimeline/BattleofMidwayTimeline",
    full_image:
      "https://public.tableau.com/static/images/Th/TheBattleofMidwayTimeline/BattleofMidwayTimeline/1.png",
    title: "The Battle for Midway Timeline",
    author: "Joshua Haines",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/swaraj1885/viz/MainGlobalTerrorism/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ma/MainGlobalTerrorism/Dashboard1/1.png",
    title: "Global terrorism hotspots.",
    author: "Swaraj Samant",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/maryorimartinez/viz/TheMagicalUniverseofHarryPotterFilms-IronViz2021/TheMagicalUniverseOfHarrypotterFilms",
    full_image:
      "https://public.tableau.com/static/images/Th/TheMagicalUniverseofHarryPotterFilms-IronViz2021/TheMagicalUniverseOfHarrypotterFilms/1.png",
    title: "The Magical Universe Of Harry Potter Films",
    author: "Maryori Martinez",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kuan.pei.lai/viz/Book1_16147435960720/PixarMovie2020byStates",
    full_image:
      "https://public.tableau.com/static/images/Bo/Book1_16147435960720/PixarMovie2020byStates/1.png",
    title: "pixar",
    author: "KUAN-PEI LAI",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/dwi.setyo.aji/viz/AvocadoInUSCity/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Av/AvocadoInUSCity/Dashboard1/1.png",
    title: "Avocado In US City",
    author: "Dwi Setyo Aji",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chia.bi.rong/viz/F1_16229586045330/F1SeasonsandDriversResults1950-2019",
    full_image:
      "https://public.tableau.com/static/images/F1/F1_16229586045330/F1SeasonsandDriversResults1950-2019/1.png",
    title: "F1 Season map and Drivers' result (1950-2019)",
    author: "Bi Rong Chia",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shikhar.misra/viz/CropProduction_16195419864340/NEWDASHBOARD",
    full_image:
      "https://public.tableau.com/static/images/Cr/CropProduction_16195419864340/NEWDASHBOARD/1.png",
    title: "Crop Production",
    author: "Shikhar Mishra",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/frances.okolo/viz/Lifeexpectancy_16240448392050/LifeExpectancy",
    full_image:
      "https://public.tableau.com/static/images/Li/Lifeexpectancy_16240448392050/LifeExpectancy/1.png",
    title: "How Long Have We Got?",
    author: "Frances Okolo",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/apoorva.kadu/viz/TableauIronVizCompetition/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ta/TableauIronVizCompetition/Dashboard1/1.png",
    title: "Tableau Iron Viz 2021 - ApoorvaKadu",
    author: "Apoorva Sandeep Kadu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ethan.lang/viz/JourneyofPerseverance/JourneyOfPerseverance",
    full_image:
      "https://public.tableau.com/static/images/Jo/JourneyofPerseverance/JourneyOfPerseverance/1.png",
    title: "Journey of Perseverance",
    author: "Ethan Lang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/grishma.shah6860/viz/Allaboutmoviesandtvshows/Allaboutmoviesandtvshows",
    full_image:
      "https://public.tableau.com/static/images/Al/Allaboutmoviesandtvshows/Allaboutmoviesandtvshows/1.png",
    title: "All about movies and tv shows",
    author: "Grishma Shah",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/alexander.goldstein/viz/NBAplayertimeoncourtpredictivemetrics/Story1",
    full_image:
      "https://public.tableau.com/static/images/NB/NBAplayertimeoncourtpredictivemetrics/Story1/1.png",
    title: "NBA Player Time on Court Predictive Metrics",
    author: "Alexander Goldstein",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/amrutha3044/viz/PhilanthropicInitiatives/Overview",
    full_image:
      "https://public.tableau.com/static/images/Ph/PhilanthropicInitiatives/Overview/1.png",
    title: "Philanthropic Initiatives",
    author: "Amrutha S",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sushanth.arunachalam/viz/WorldWideTerrorismAttacksAnalysis/WorldWideTerrorismAttacksAnalysis",
    full_image:
      "https://public.tableau.com/static/images/Wo/WorldWideTerrorismAttacksAnalysis/WorldWideTerrorismAttacksAnalysis/1.png",
    title: "World Wide Terrorism Attacks Analysis",
    author: "SUSHANTH ARUNACHALAM",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/abid/viz/PizzaUS/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Pi/PizzaUS/Dashboard1/1.png",
    title: "Pizza US",
    author: "Abid Ali Awan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/louisyu/viz/HastheGamingTrendchangedinJapan/Explore",
    full_image:
      "https://public.tableau.com/static/images/Ha/HastheGamingTrendchangedinJapan/Explore/1.png",
    title: "Has the Gaming Trend changed in Japan?",
    author: "Louis Yu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kevin.flerlage/viz/TheHistoryofTableausIronViz/TheHistoryofTableausIronViz",
    full_image:
      "https://public.tableau.com/static/images/Th/TheHistoryofTableausIronViz/TheHistoryofTableausIronViz/1.png",
    title: "The History of Tableau's Iron Viz",
    author: "Kevin Flerlage",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/nicole.klassen/viz/RepresentationMatters/LGBTQRepresentationinMedia",
    full_image:
      "https://public.tableau.com/static/images/Re/RepresentationMatters/LGBTQRepresentationinMedia/1.png",
    title: "Representation Matters - LGBTQ Representation in Media",
    author: "Nicole Klassen",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rahul7922/viz/BEEAwesome_5/BEEAwesome",
    full_image:
      "https://public.tableau.com/static/images/BE/BEEAwesome_5/BEEAwesome/1.png",
    title: "BEE Awesome",
    author: "Rahul Patil",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/joshua.grist/viz/BirminghamBearsRunScorers/Story1",
    full_image:
      "https://public.tableau.com/static/images/Bi/BirminghamBearsRunScorers/Story1/1.png",
    title: "Birmingham Bears Run Scorers",
    author: "Joshua Grist",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chensheng.wen/viz/filters_16251032257220/StudyonAirbnbTravelersPreferenceinSeattleArea",
    full_image:
      "https://public.tableau.com/static/images/fi/filters_16251032257220/StudyonAirbnbTravelersPreferenceinSeattleArea/1.png",
    title: "Study on Airbnb Traveler\u2019s Preference in Seattle Area ",
    author: "chensheng wen",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/andreas.wiehl/viz/TopOfTheWorld/TopOfTheWorld",
    full_image:
      "https://public.tableau.com/static/images/To/TopOfTheWorld/TopOfTheWorld/1.png",
    title: "Top Of The World",
    author: "Andreas Wiehl",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/oladipupo.zannou/viz/SALESTRENDDASHBOARD_16130661204350/SalesTrend",
    full_image:
      "https://public.tableau.com/static/images/SA/SALESTRENDDASHBOARD_16130661204350/SalesTrend/1.png",
    title: "SALES TREND DASHBOARD",
    author: "ZANNOU OLADIPO",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/rajavel.selvaraj.ganesan/viz/ARRahmanIndianOscarWinner-Music/Home",
    full_image:
      "https://public.tableau.com/static/images/AR/ARRahmanIndianOscarWinner-Music/Home/1.png",
    title: "AR Rahman Indian Oscar Winner - Music by",
    author: "Rajavel Selvaraj Ganesan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/omkar.arora/viz/VideoGamesSales_16227471766580/VideoGameSales",
    full_image:
      "https://public.tableau.com/static/images/Vi/VideoGamesSales_16227471766580/VideoGameSales/1.png",
    title: "Video Games Sales",
    author: "Omkar Arora",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/jon.zimmerman/viz/IronViz_16250856892150/Dashboardv2",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz_16250856892150/Dashboardv2/1.png",
    title: "County Level Presidential Elections Dashboard",
    author: "Jonathan Zimmerman",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/brulia.analytics/viz/60YearsofPunkRock/60YearsofPunkRock",
    full_image:
      "https://public.tableau.com/static/images/60/60YearsofPunkRock/60YearsofPunkRock/1.png",
    title: "60 Years of Punk Rock",
    author: "Ken Brulia",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/marie.camille.vergez/viz/Beer_16243716736330/Tableaudebord1",
    full_image:
      "https://public.tableau.com/static/images/Be/Beer_16243716736330/Tableaudebord1/1.png",
    title: "Cave \u00e0 Bi\u00e8res - Edition Paris",
    author: "Marie-Camille Vergez",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ellie.riitters/viz/IronVizSubmission-TeamAlpha/MoviesofQuarantine",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizSubmission-TeamAlpha/MoviesofQuarantine/1.png",
    title: "Movies of Quarantine ",
    author: "Ellie Riitters",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/deepkumar.gala/viz/MySpotify-IronViz/MySpotify",
    full_image:
      "https://public.tableau.com/static/images/My/MySpotify-IronViz/MySpotify/1.png",
    title: "5 years of streaming joy!",
    author: "Deepkumar Gala",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/aravindan1170/viz/MyAmazingViz_16251618611480/Story1",
    full_image:
      "https://public.tableau.com/static/images/My/MyAmazingViz_16251618611480/Story1/1.png",
    title: "My Amazing Viz",
    author: "Aravindan S",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/janie.gilbert8047/viz/ArtisticSwimmingAtTheOlympics/ARTISTICSWIM",
    full_image:
      "https://public.tableau.com/static/images/Ar/ArtisticSwimmingAtTheOlympics/ARTISTICSWIM/1.png",
    title: "ARTISTIC SWIMMING AT THE OLYMPICS",
    author: "Janie Gilbert",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lillian.pham/viz/IronVisBLOCKBUSTER_LillianPham/Dashboard3",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVisBLOCKBUSTER_LillianPham/Dashboard3/1.png",
    title: "Blockbuster",
    author: "Lillian Pham",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ebelechukwu/viz/Afrobeats/Afrobeats",
    full_image:
      "https://public.tableau.com/static/images/Af/Afrobeats/Afrobeats/1.png",
    title: "Afrobeats",
    author: "Ebelechukwu Monye",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/babu.s2558/viz/GameOfThrones_16240856602870/GOT_Deaths",
    full_image:
      "https://public.tableau.com/static/images/Ga/GameOfThrones_16240856602870/GOT_Deaths/1.png",
    title: "Game Of Thrones",
    author: "Babu Shankar",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/simon.arira/viz/TheLifeofCortoMALTESE/IRONVIZ_CORTO_MATLTESE",
    full_image:
      "https://public.tableau.com/static/images/Th/TheLifeofCortoMALTESE/IRONVIZ_CORTO_MATLTESE/1.png",
    title: "IronViz 2021 : The Life of Corto MALTESE",
    author: "SIMON ARIRA",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/fran.ois8865/viz/FrenchResistance/Rsistance",
    full_image:
      "https://public.tableau.com/static/images/Fr/FrenchResistance/Rsistance/1.png",
    title: "French Resistance",
    author: "Fran\u00e7ois CAVE",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/4dgislab/viz/JPN_RainySeasonStartOver/D1",
    full_image:
      "https://public.tableau.com/static/images/JP/JPN_RainySeasonStartOver/D1/1.png",
    title: "JPN_RainySeasonStart&Over",
    author: "Tadayuki Tokunaga",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/bj.price/viz/WaltDisneyWorldMarathonResults2000-2018/DisneyMarathonHistory2000-2018",
    full_image:
      "https://public.tableau.com/static/images/Wa/WaltDisneyWorldMarathonResults2000-2018/DisneyMarathonHistory2000-2018/1.png",
    title: "Walt Disney World Marathon Results 2000-2018",
    author: "Bj Price",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lindsey3327/viz/Representation_16242112746800/Story1",
    full_image:
      "https://public.tableau.com/static/images/Re/Representation_16242112746800/Story1/1.png",
    title: "Representation Matters",
    author: "Lindsey Morgan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/piya.chakraborty/viz/Ironviz2021IMDBMovieDashboard-PiyaChakraborty/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ir/Ironviz2021IMDBMovieDashboard-PiyaChakraborty/Dashboard1/1.png",
    title: "Movie Recommendation Tool",
    author: "Piya Chakraborty",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/arun.kumar1694/viz/TableauAssignment_16203149270700/SalesandProfitsDistribution",
    full_image:
      "https://public.tableau.com/static/images/Ta/TableauAssignment_16203149270700/SalesandProfitsDistribution/1.png",
    title: "Sales and Profits Distribution",
    author: "Arun Kumar  Omkaram",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/dikshant.oswal/viz/Covid-19Impact_16251533879010/Story1",
    full_image:
      "https://public.tableau.com/static/images/Co/Covid-19Impact_16251533879010/Story1/1.png",
    title: "Covid-19 Impact",
    author: "Dikshant Oswal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ellie.kohler/viz/GenresinRomance/Romance",
    full_image:
      "https://public.tableau.com/static/images/Ge/GenresinRomance/Romance/1.png",
    title: "Genres in Romance",
    author: "Ellie Kohler",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/faisal.ali4688/viz/OakVillage_SBDEnergyDashboard/Storywcost",
    full_image:
      "https://public.tableau.com/static/images/Oa/OakVillage_SBDEnergyDashboard/Storywcost/1.png",
    title: "Energy Dashboard for a Building",
    author: "Faisal Ali",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/sarah6569/viz/BikeTagGlobal-Test/BikeTag",
    full_image:
      "https://public.tableau.com/static/images/Bi/BikeTagGlobal-Test/BikeTag/1.png",
    title: "Bike Tag",
    author: "Sarah Mannheim",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ashwin5188/viz/AshwinsJournalofSunsNRoses/AshwinsJournal",
    full_image:
      "https://public.tableau.com/static/images/As/AshwinsJournalofSunsNRoses/AshwinsJournal/1.png",
    title: "Ashwin's Journal of Suns N' Roses",
    author: "Ashwin Govindaraj",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/trinita/viz/EllenPompeo/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/El/EllenPompeo/Dashboard1/1.png",
    title: "Ellen in the Radial",
    author: "Trinita J",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/joe.love/viz/MLS_15995933166810/Salaries",
    full_image:
      "https://public.tableau.com/static/images/ML/MLS_15995933166810/Salaries/1.png",
    title: "Major League Soccer",
    author: "Joe Love",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mike.lane/viz/VerumGuideAtlasoftheGreatDungeonsandDragonsWarIronViz/Readme",
    full_image:
      "https://public.tableau.com/static/images/Ve/VerumGuideAtlasoftheGreatDungeonsandDragonsWarIronViz/Readme/1.png",
    title: "Verum Guide: Atlas of the Great Dungeons and Dragons War",
    author: "Michael Lane",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ojoswi/viz/IronViz_2021-JOY/TableauIronViz2021",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz_2021-JOY/TableauIronViz2021/1.png",
    title: "IRONVIZ 2021 JOY",
    author: "Ojoswi  Basu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/calloni/viz/Iron-Viz-Resume-Template/Resume",
    full_image:
      "https://public.tableau.com/static/images/Ir/Iron-Viz-Resume-Template/Resume/1.png",
    title: "Tableau Public Resume Template",
    author: "Rodrigo Calloni",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kaushik.chandra.nagarajan/viz/Entertainertableau/MainPage",
    full_image:
      "https://public.tableau.com/static/images/En/Entertainertableau/MainPage/1.png",
    title: "Entertainers' Dashboard",
    author: "Kaushik Chandra Nagarajan",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mahima.nayak/viz/Lost_In_Space/LOSTINSPACE",
    full_image:
      "https://public.tableau.com/static/images/Lo/Lost_In_Space/LOSTINSPACE/1.png",
    title: "LOST_IN_SPACE",
    author: "Mahima Nayak",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/winslow8285/viz/YogaPoses/YOGA4",
    full_image:
      "https://public.tableau.com/static/images/Yo/YogaPoses/YOGA4/1.png",
    title: "Yoga",
    author: "Winslow Warren",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mubarak.ganiyu/viz/EconomicFreedomIndex_16243917027740/EconomicFreedomIndexfrom1995to2021",
    full_image:
      "https://public.tableau.com/static/images/Ec/EconomicFreedomIndex_16243917027740/EconomicFreedomIndexfrom1995to2021/1.png",
    title: "Economic Freedom Index from 1995 to 2021",
    author: "Mubarak Ganiyu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/simon.di.giacomo/viz/WhichSimpson/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Wh/WhichSimpson/Dashboard1/1.png",
    title: "Which Simpson?",
    author: "Simon Di Giacomo",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/balintvagyok/viz/Book1_16216907170140/Viz",
    full_image:
      "https://public.tableau.com/static/images/Bo/Book1_16216907170140/Viz/1.png",
    title: "San Antonio Spurs Big 3",
    author: "B\u00e1lint T\u00f3th",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yoann.duval/viz/MyNikeAirMaxShoes/NIKEAIRMAX",
    full_image:
      "https://public.tableau.com/static/images/My/MyNikeAirMaxShoes/NIKEAIRMAX/1.png",
    title: "My Nike Air Max Shoes",
    author: "Yoann Duval",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lina.c2805/viz/IronViz2021_16252023461270/Videogamemarket35yearsOverview",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronViz2021_16252023461270/Videogamemarket35yearsOverview/1.png",
    title: "IronViz 2021",
    author: "Lina Cao",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/william.xie1492/viz/TigervsLion/TIGERVSLIONWHOWILLWININAFIGHT",
    full_image:
      "https://public.tableau.com/static/images/Ti/TigervsLion/TIGERVSLIONWHOWILLWININAFIGHT/1.png",
    title: "Tiger vs Lion",
    author: "william xie",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shaibaz8816/viz/GlobalTrackerCovid-19/GlobalTrackerCovid-19",
    full_image:
      "https://public.tableau.com/static/images/Gl/GlobalTrackerCovid-19/GlobalTrackerCovid-19/1.png",
    title: "Global Tracker : Covid - 19",
    author: "Shaibaz Mulla",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/arpita.singha/viz/shopperstopproject/Sheet1",
    full_image:
      "https://public.tableau.com/static/images/sh/shopperstopproject/Sheet1/1.png",
    title: "Shoppersstop Project",
    author: "Arpita Singha",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ashishbidap/viz/CannabisMarketAnalysis-COMA-Dashboard/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ca/CannabisMarketAnalysis-COMA-Dashboard/Dashboard1/1.png",
    title: "Cannabis Market Analysis - CO & MA",
    author: "Ashishkumar Bidap",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/bo.mccready8742/viz/HorrorFilms-AVisualHistoryBoMcCreadyIronViz2021/HorrorFinal",
    full_image:
      "https://public.tableau.com/static/images/Ho/HorrorFilms-AVisualHistoryBoMcCreadyIronViz2021/HorrorFinal/1.png",
    title: "Horror Films: A Visual History",
    author: "Bo McCready",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/helen.wall/viz/WorldFlyingDistances/FlyingDistancesandTimes",
    full_image:
      "https://public.tableau.com/static/images/Wo/WorldFlyingDistances/FlyingDistancesandTimes/1.png",
    title: "Let's Fly Away This Summer",
    author: "Helen Wall",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/akash.navneeth/viz/CopyofISMFinalProductDataVisualizations-AkashNavneeth/Q1-TravelFreq",
    full_image:
      "https://public.tableau.com/static/images/Co/CopyofISMFinalProductDataVisualizations-AkashNavneeth/Q1-TravelFreq/1.png",
    title: "Copy of ISM Final Product Data Visualizations - Akash Navneeth",
    author: "Akash Navneeth",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mfundo5004/viz/LeTourDeFrancOverrallAnalysisDashboard/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Le/LeTourDeFrancOverrallAnalysisDashboard/Dashboard1/1.png",
    title: "Le Tour De Franc Overrall Analysis Dashboard",
    author: "Mfundo Booi",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kim.nguyen6455/viz/TasteofHappiness/TasteofHappiness",
    full_image:
      "https://public.tableau.com/static/images/Ta/TasteofHappiness/TasteofHappiness/1.png",
    title: "Taste of Happiness",
    author: "Kim Nguyen",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/naveen.kalyanasamy3692/viz/HarryPotterPeelingBacktheLayers/HP",
    full_image:
      "https://public.tableau.com/static/images/Ha/HarryPotterPeelingBacktheLayers/HP/1.png",
    title: "Harry Potter: Peeling Back the Layers",
    author: "Naveen Kalyanasamy",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/asmita3078/viz/SuperstoreAnalysis_16162484914030/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Su/SuperstoreAnalysis_16162484914030/Dashboard1/1.png",
    title: "Superstore Data Anlalysis",
    author: "Asmita More",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/devesh5578/viz/STARTUPANALYSIS_15929287455700/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/ST/STARTUPANALYSIS_15929287455700/Dashboard1/1.png",
    title: "STARTUPANALYSIS",
    author: "Devesh Prasad",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/irene.saravia/viz/KnowYourPoke3/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Kn/KnowYourPoke3/Dashboard1/1.png",
    title: "Know your poke",
    author: "Irene Saravia Enrech",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/alexander.bielstein/viz/7DaysViciousCoronavirusDeath/covid19delta",
    full_image:
      "https://public.tableau.com/static/images/7D/7DaysViciousCoronavirusDeath/covid19delta/1.png",
    title: "7DaysViciousCoronavirusDeath",
    author: "Alex Bielstein",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/kate.davidson1530/viz/Meteorites_16252367184100/Meteorites",
    full_image:
      "https://public.tableau.com/static/images/Me/Meteorites_16252367184100/Meteorites/1.png",
    title: "Make a Wish",
    author: "Kate Davidson",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ilija.stojic/viz/MentalBubbleWrap/MentalBubbleWrap",
    full_image:
      "https://public.tableau.com/static/images/Me/MentalBubbleWrap/MentalBubbleWrap/1.png",
    title: "Mental Bubble Wrap",
    author: "Ilija Stojic",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/besharam81/viz/FatalPoliceShootings_16250092748320/Flag",
    full_image:
      "https://public.tableau.com/static/images/Fa/FatalPoliceShootings_16250092748320/Flag/1.png",
    title: "Fatal Police Shootings in the USA",
    author: "Anish bhatt",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/excel.kok.bisa/viz/COVID-19sVaccinations-WIP/VaccinationDashboard",
    full_image:
      "https://public.tableau.com/static/images/CO/COVID-19sVaccinations-WIP/VaccinationDashboard/1.png",
    title: "COVID-19's Vaccination Dashboard",
    author: "Arifudin Miftakhul Huda",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/usama.ather/viz/FIFAWorldCupHistory_16251905207180/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/FI/FIFAWorldCupHistory_16251905207180/Dashboard1/1.png",
    title: "FIFA World Cup History",
    author: "Usama Ather",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/reg.chand/viz/COVID-19_16118745018620/Covid-19",
    full_image:
      "https://public.tableau.com/static/images/CO/COVID-19_16118745018620/Covid-19/1.png",
    title: "Covid-19",
    author: "Reg Chand",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/coral8290/viz/IronVizEntry-CoralsJoy/InspiringAuthor",
    full_image:
      "https://public.tableau.com/static/images/Ir/IronVizEntry-CoralsJoy/InspiringAuthor/1.png",
    title: "Inspiring Author",
    author: "Coral Trevino",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/tejasvani/viz/REVENUE_16245288094800/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/RE/REVENUE_16245288094800/Dashboard1/1.png",
    title: "MOVIE REVENUE COMPARISON",
    author: "TEJASVANI SHARMA",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/charlotte.flower3923/viz/ironvizzzzz/EgyptDashboard",
    full_image:
      "https://public.tableau.com/static/images/ir/ironvizzzzz/EgyptDashboard/1.png",
    title: "The Egyptian Empire",
    author: "Charlotte Flower",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/popescu.madalina/viz/SUDOKU/Sudoku",
    full_image:
      "https://public.tableau.com/static/images/SU/SUDOKU/Sudoku/1.png",
    title: "Sudoku",
    author: "Madalina Popescu",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/phyllis.tay/viz/TopAmazonBooks/Top10Picks",
    full_image:
      "https://public.tableau.com/static/images/To/TopAmazonBooks/Top10Picks/1.png",
    title: "Top Amazon Books",
    author: "Phyllis Tay",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/chandana3305/viz/HappinessRateWorld-wide/VisualAnalyticsandInsightsintoHappinessRateworld-wide",
    full_image:
      "https://public.tableau.com/static/images/Ha/HappinessRateWorld-wide/VisualAnalyticsandInsightsintoHappinessRateworld-wide/1.png",
    title: "Visual Analytics and Insights into Happiness Rate world-wide ",
    author: "Damarla Venkata Sai Chandana",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/tasneem6789/viz/Vizcompetition/Dashboard2",
    full_image:
      "https://public.tableau.com/static/images/Vi/Vizcompetition/Dashboard2/1.png",
    title: "Hollywood Box-Office",
    author: "Tasneem Attarwala",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ch.v.s.satyamurty/viz/project_16197219459180/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/pr/project_16197219459180/Dashboard1/1.png",
    title: "Insurance",
    author: "CH V S Satyamurty",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/vani.agarwal/viz/MeloManiactriochallengeWeek6/MeloManiac",
    full_image:
      "https://public.tableau.com/static/images/Me/MeloManiactriochallengeWeek6/MeloManiac/1.png",
    title: "Melo Maniac | #IronViz | #triochallenge | Week6",
    author: "Vani Agarwal",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/liam6844/viz/HealthCheck_16211700687600/CanyoumeasureHappiness",
    full_image:
      "https://public.tableau.com/static/images/He/HealthCheck_16211700687600/CanyoumeasureHappiness/1.png",
    title: "Health Check by Liam",
    author: "Liam Horn",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/shubham2132/viz/Covid-19CasesAsia/Sheet1",
    full_image:
      "https://public.tableau.com/static/images/Co/Covid-19CasesAsia/Sheet1/1.png",
    title: "Covid-19 Cases Asia",
    author: "Shubham Shah",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/keith.greiner/viz/Earthquakes7plusmap/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Ea/Earthquakes7plusmap/Dashboard1/1.png",
    title: "120 Years of Earthquakes Rated 7+ by Month and Location",
    author: "Keith Greiner",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/andrew.terrell/viz/WhatMakesHumansHappy/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Wh/WhatMakesHumansHappy/Dashboard1/1.png",
    title: "What Makes Humans Happy?",
    author: "Andrew Terrell",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/bkornell/viz/54InstancesofJoy/54InstancesofJoy",
    full_image:
      "https://public.tableau.com/static/images/54/54InstancesofJoy/54InstancesofJoy/1.png",
    title: "54 Instances of Joy",
    author: "Branden Kornell",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/cchampio/viz/Lovecraft/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/Lo/Lovecraft/Dashboard1/1.png",
    title: "The Lovecraft Universe ",
    author: "Cody Champion",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/mithillesh.kumar.p/viz/trial2_16247115766220/Story",
    full_image:
      "https://public.tableau.com/static/images/tr/trial2_16247115766220/Story/1.png",
    title: "SDG 7 pioneers of 2017",
    author: "MITHILLESH KUMAR P",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/anil.jhurani/viz/AirBnb-RussianGermanMuseum/Russian-GermanMuseumProximity",
    full_image:
      "https://public.tableau.com/static/images/Ai/AirBnb-RussianGermanMuseum/Russian-GermanMuseumProximity/1.png",
    title: "Russian German Museum Proximity ",
    author: "Anil Jhurani",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/ankit.rai3800/viz/PokemonsVisualization/PokemonViz",
    full_image:
      "https://public.tableau.com/static/images/Po/PokemonsVisualization/PokemonViz/1.png",
    title: "Pokemons Visualizations",
    author: "Ankit Rai",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/vijay.kumar.bhagchandani/viz/IPL_16240210202250/Dashboard1",
    full_image:
      "https://public.tableau.com/static/images/IP/IPL_16240210202250/Dashboard1/1.png",
    title: "IPL",
    author: "Vijay Kumar Bhagchandani",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/david.brezler/viz/DASNYManagedProjects/DASNYMngdProjects",
    full_image:
      "https://public.tableau.com/static/images/DA/DASNYManagedProjects/DASNYMngdProjects/1.png",
    title: "DASNY Managed projects 2014 - 2020",
    author: "David Brezler",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/erick.williams/viz/DenverCrimeBuild/Dashboard",
    full_image:
      "https://public.tableau.com/static/images/De/DenverCrimeBuild/Dashboard/1.png",
    title: "Denver Crime Build",
    author: "Erick Williams",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/yanchen5989/viz/ChadYanchenZhangCV/YanchenZhangCVGofull-screenforbestview",
    full_image:
      "https://public.tableau.com/static/images/Ch/ChadYanchenZhangCV/YanchenZhangCVGofull-screenforbestview/1.png",
    title:
      "C.V. Revitalized: the dynamic path of an early-career international minority scholar",
    author: "Yanchen Zhang",
  },
  {
    interactive:
      "https://public.tableau.com/app/profile/lauren.smith6599/viz/ExploringtheWorldofZooTycoon/PresentDay",
    full_image:
      "https://public.tableau.com/static/images/Ex/ExploringtheWorldofZooTycoon/PresentDay/1.png",
    title: "Exploring the World of Zoo Tycoon",
    author: "Lauren Smith",
  },
];
