<script>
	import { submissions } from "./ironviz_submissions";
	import Card from "./Card.svelte";
	// const submission = submissions[0] 
</script>


<main>
	{#each submissions as submission}
		<Card {...submission}/>
	{/each}
</main>
<style>
	main {
		/* line-height: 0; */
   		column-count: 4;
		column-gap: 20px;
		row-gap: 20px;
	}


</style>